/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import o from "./Collection.js";
import "./asyncUtils.js";
import "./Error.js";
import "./Logger.js";
import "./accessorSupport/watch.js";
import { autorun as s } from "./accessorSupport/trackingUtils.js";
import { property as e } from "./accessorSupport/decorators/property.js";
import { subclass as r } from "./accessorSupport/decorators/subclass.js";
let i = class extends o {
  constructor(t) {
    super(t), this.getCollections = null;
  }
  initialize() {
    this.addHandles(s(() => this._refresh()));
  }
  destroy() {
    this.getCollections = null;
  }
  _refresh() {
    const t = null != this.getCollections ? this.getCollections() : null;
    if (null == t) return void this.removeAll();
    let o = 0;
    for (const s of t) null != s && (o = this._processCollection(o, s));
    this.splice(o, this.length);
  }
  _createNewInstance(t) {
    return new o(t);
  }
  _processCollection(t, o) {
    if (!o) return t;
    const s = this.itemFilterFunction ?? (t => !!t);
    for (const e of o) if (e) {
      if (s(e)) {
        const o = this.indexOf(e, t);
        o >= 0 ? o !== t && this.reorder(e, t) : this.add(e, t), ++t;
      }
      if (this.getChildrenFunction) {
        const o = this.getChildrenFunction(e);
        if (Array.isArray(o)) for (const s of o) t = this._processCollection(t, s);else t = this._processCollection(t, o);
      }
    }
    return t;
  }
};
t([e()], i.prototype, "getCollections", void 0), t([e()], i.prototype, "getChildrenFunction", void 0), t([e()], i.prototype, "itemFilterFunction", void 0), i = t([r("esri.core.CollectionFlattener")], i);
const l = i;
export { l as default };